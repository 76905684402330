var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"update-modal","no-close-on-backdrop":"","ok-title":"確認","centered":"","header-bg-variant":"primary","ok-only":""},on:{"hidden":_vm.handleHide,"close":_vm.handleHide},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h4',{staticClass:"m-0 model-header"},[_vm._v(" "+_vm._s(_vm.powerDataItem.id ? '編輯權限' : '新增權限')+" ")])]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100 text-right"},[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isBusy},on:{"click":_vm.handleOk}},[(!_vm.isBusy)?_c('span',[_vm._v("確認")]):_c('b-spinner',{attrs:{"small":""}})],1)],1)]},proxy:true}])},[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label-for":"state"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('label',{staticClass:"mb-0"},[_vm._v(" 狀態 "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])])]},proxy:true}],null,true)},[_c('v-select',{attrs:{"options":_vm.stateOptions,"clearable":false,"reduce":function (option) { return option.value; },"placeholder":"請選擇狀態"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [(searching)?[_vm._v(" 沒有"),_c('em',[_vm._v(_vm._s(search))]),_vm._v(" 的相關結果 ")]:_c('div',[_c('small',[_vm._v("暫無資料")])])]}},{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"mr-50 border state-dot",class:{ 'dot-gray': !option.value, 'dot-green-selected': option.value }}),_vm._v(" "+_vm._s(option.label)+" ")])]}},{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"mr-50 border state-dot",class:{ 'dot-gray': !option.value, 'dot-green-selected': option.value }}),_vm._v(" "+_vm._s(option.label)+" ")])]}}],null,true),model:{value:(_vm.powerData.state),callback:function ($$v) {_vm.$set(_vm.powerData, "state", $$v)},expression:"powerData.state"}})],1),_c('validation-provider',{attrs:{"name":"權限名稱","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"mb-0"},[_vm._v(" 權限名稱 "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"trim":"","placeholder":_vm.powerDataItem.name ? _vm.powerDataItem.name : '請輸入權限名稱'},model:{value:(_vm.powerData.name),callback:function ($$v) {_vm.$set(_vm.powerData, "name", $$v)},expression:"powerData.name"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"群組","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"group"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"mb-0"},[_vm._v(" 群組 "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"trim":"","placeholder":_vm.powerDataItem.group ? _vm.powerDataItem.group : '請輸入群組名稱'},model:{value:(_vm.powerData.group),callback:function ($$v) {_vm.$set(_vm.powerData, "group", $$v)},expression:"powerData.group"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"權限路由","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"route_path"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"mb-0"},[_vm._v(" 權限路由 "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"route_path","state":errors.length > 0 ? false : null,"trim":"","placeholder":_vm.powerDataItem.route_path ? _vm.powerDataItem.route_path : '請輸入權限路由',"disabled":_vm.powerDataItem.id != null},model:{value:(_vm.powerData.route_path),callback:function ($$v) {_vm.$set(_vm.powerData, "route_path", $$v)},expression:"powerData.route_path"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('div',[_c('label',[_vm._v("說明")]),_c('b-form-textarea',{attrs:{"placeholder":_vm.powerData.content ? _vm.powerData.content : '說明',"state":_vm.powerData.content.length <= _vm.maxChar ? null : false,"rows":"5"},model:{value:(_vm.powerData.content),callback:function ($$v) {_vm.$set(_vm.powerData, "content", $$v)},expression:"powerData.content"}}),_c('small',{staticClass:"textarea-counter-value float-right",class:_vm.powerData.content.length > _vm.maxChar ? 'bg-danger' : ''},[_c('span',{staticClass:"char-count"},[_vm._v(_vm._s(_vm.powerData.content.length))]),_vm._v(" / "+_vm._s(_vm.maxChar)+" ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }